import isAfter from 'date-fns/isAfter';
import { SearchBarValues } from '../components/SearchBox';
import parseToDateString from '../constants/parseToDateString';
import { SEARCHBAR_KEY, VISITOR_PARTY } from '../constants/storage';
import getStorageWithExpiry from './getStorageWithExpiry';
import isDate from './isDate';

const correctDate = (value: string) => {
    const date = new Date(value);
    if (isDate(date) && isAfter(date, new Date())) {
        return parseToDateString(date);
    }
    return undefined;
};

export const setSearchBoxValuesToStorage = (searchBarValues: SearchBarValues) => {
    const storageUtils = getStorageWithExpiry('local');
    storageUtils?.setItem(SEARCHBAR_KEY, JSON.stringify(searchBarValues));

    let visitorParty = 'other';
    if (
        (searchBarValues.booking.amountChildren ?? 0) > 0 ||
        (searchBarValues.booking.amountBabies ?? 0) > 0 ||
        (searchBarValues.booking.amountYouths ?? 0) > 0
    ) {
        visitorParty = 'family';
    } else if (searchBarValues.booking.amountAdults === 2) {
        visitorParty = 'couple';
    } else if ((searchBarValues.booking.amountAdults ?? 0) > 6) {
        visitorParty = 'group';
    }
    storageUtils?.setItem(VISITOR_PARTY, visitorParty, 365);
};

export const getSearchBoxValuesFromStorage = (): SearchBarValues | undefined => {
    const storageUtils = getStorageWithExpiry('local');

    if (!storageUtils) {
        return undefined;
    }

    const { getItem } = storageUtils;
    const searchString = getItem(SEARCHBAR_KEY);

    if (!searchString) {
        return undefined;
    }
    const searchObj = JSON.parse(searchString);
    let { arrivalDate, departureDate } = searchObj.booking ?? {};

    arrivalDate = correctDate(arrivalDate);
    departureDate = correctDate(departureDate);

    if (!arrivalDate || !departureDate) {
        arrivalDate = '';
        departureDate = '';
    }

    return {
        booking: { ...searchObj.booking, arrivalDate, departureDate },
        ...(searchObj.accommodationType && { accommodationType: searchObj.accommodationType }),
    };
};
