import Image from 'next/image';
import { useRouter } from 'next/router';
import React from 'react';
import styled from 'styled-components';
import { useTenantContext } from '../../context/TenantContext';
import { getSearchBoxValuesFromStorage, setSearchBoxValuesToStorage } from '../../utils/searchBoxValuesFromStorage';
import { withDefaultBookingValues } from '../../utils/trip';
import Formed from '../Formed';
import Page from '../Page';
import SearchBox, { getInitialSearchBoxValues, getSearchValidationSchema, SearchBarValues } from '../SearchBox';
import { SearchVariant } from '../SearchForm';
import ContentWrapper from '../ContentWrapper';

const Index = () => {
    const { brandConfig } = useTenantContext();
    return (
        <StyledPage>
            <ContentWrapper>
                {brandConfig.indexImage && (
                    <ImageContainer>
                        <Image
                            alt={brandConfig.seo.siteName}
                            src={brandConfig.indexImage}
                            layout="fill"
                            objectFit="cover"
                            quality={100}
                            loading="lazy"
                            placeholder="blur"
                        />
                    </ImageContainer>
                )}
                <Wrapper>
                    <IndexSearchBox />
                </Wrapper>
            </ContentWrapper>
        </StyledPage>
    );
};

export default Index;

export const IndexSearchBox = ({ variant = 'homeBar' }: { variant?: SearchVariant }) => {
    const router = useRouter();
    const validationSchema = getSearchValidationSchema();
    const { brandConfig } = useTenantContext();

    return (
        <Formed<SearchBarValues>
            handleSubmit={async values => {
                const { accommodationType, booking, brand } = values;
                setSearchBoxValuesToStorage(values);
                const orFilters = brand !== 'all' && !!brand && `_brand.${brand}`;
                await router.push({
                    pathname: `/search`,
                    query: {
                        ...withDefaultBookingValues(booking),
                        ...(accommodationType && { accommodationType }),
                        ...(brand && !!orFilters && { orFilters }),
                        trip: undefined,
                    },
                });
            }}
            initialValues={getInitialSearchBoxValues(brandConfig, getSearchBoxValuesFromStorage())}
            validationSchema={validationSchema}
            skipPrompt
        >
            {({ isSubmitting }) => (
                <SearchBox variant={variant} isSubmitting={isSubmitting} hideHelper hideCalendarIcon />
            )}
        </Formed>
    );
};

const Wrapper = styled.div`
    max-width: 100%;
    margin: 0 auto;
    z-index: ${({ theme }) => theme.zIndices.docked};
    position: relative;
`;

const StyledPage = styled(Page)`
    min-height: 100vh;
    display: flex;
    align-items: center;

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        min-height: 70vh;
    }
`;

const ImageContainer = styled.div`
    display: inline-block;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    height: unset;
    position: absolute;

    ::after {
        padding-top: calc(9 / 16 * 100%);
        content: '';
        display: block;
    }
`;
